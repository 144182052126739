var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "card" }, [
    _c("div", { staticClass: "card-header" }, [
      _c("div", { staticClass: "row" }, [
        _c(
          "div",
          { staticClass: "form-group col-12 text-right" },
          [
            _c(
              "router-link",
              { attrs: { to: { name: "singlePost", params: { id: "new" } } } },
              [
                _c("BaseButton", { attrs: { type: "green", gradient: "" } }, [
                  _vm._v(
                    " " +
                      _vm._s(_vm.$t("create")) +
                      " " +
                      _vm._s(_vm.$tc("post")) +
                      " "
                  ),
                ]),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _c(
        "div",
        { staticClass: "row justify-content-between align-items-end" },
        [
          _c(
            "div",
            { staticClass: "col-md-3" },
            [
              _c("label", { staticClass: "control-label" }, [
                _vm._v(
                  _vm._s(_vm.$t("filterBy", { item: _vm.$tc("category") }))
                ),
              ]),
              _c(
                "BaseDropdown",
                {
                  attrs: {
                    "title-classes": "btn btn-secondary",
                    title: _vm.activeCategoryTitle,
                  },
                },
                [
                  _c(
                    "a",
                    {
                      staticClass: "dropdown-item",
                      on: {
                        click: function ($event) {
                          return _vm.filterByCategory("")
                        },
                      },
                    },
                    [
                      _vm._v(
                        _vm._s(_vm.$t("all")) +
                          " " +
                          _vm._s(_vm.$tc("category", 2))
                      ),
                    ]
                  ),
                  _vm._l(_vm.categories, function (cat) {
                    return _c(
                      "a",
                      {
                        key: cat.alias + "category",
                        staticClass: "dropdown-item",
                        on: {
                          click: function ($event) {
                            return _vm.filterByCategory(cat)
                          },
                        },
                      },
                      [_vm._v(_vm._s(cat.name))]
                    )
                  }),
                ],
                2
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "col-md-3" },
            [
              _c("label", { staticClass: "control-label" }, [
                _vm._v(_vm._s(_vm.$t("filterBy", { item: _vm.$tc("author") }))),
              ]),
              _c(
                "BaseDropdown",
                {
                  attrs: {
                    "title-classes": "btn btn-secondary",
                    title: _vm.activeAuthorTitle,
                  },
                },
                [
                  _c(
                    "a",
                    {
                      staticClass: "dropdown-item",
                      on: {
                        click: function ($event) {
                          return _vm.filterByAuthor("")
                        },
                      },
                    },
                    [
                      _vm._v(
                        _vm._s(_vm.$t("all")) +
                          " " +
                          _vm._s(_vm.$tc("author", 2))
                      ),
                    ]
                  ),
                  _vm._l(_vm.users, function (user) {
                    return _c(
                      "a",
                      {
                        key: user.username + "author",
                        staticClass: "dropdown-item",
                        on: {
                          click: function ($event) {
                            return _vm.filterByAuthor(user)
                          },
                        },
                      },
                      [_vm._v(_vm._s(user.username))]
                    )
                  }),
                ],
                2
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "col-md-3" },
            [
              _c("BaseInput", {
                staticClass: "search",
                attrs: { placeholder: _vm.$t("search") },
                on: {
                  keyup: function ($event) {
                    $event.preventDefault()
                    $event.stopPropagation()
                    return _vm.lazyType(_vm.filterBySearch, _vm.lazyTypeDelay)
                  },
                },
                model: {
                  value: _vm.keyword,
                  callback: function ($$v) {
                    _vm.keyword = $$v
                  },
                  expression: "keyword",
                },
              }),
            ],
            1
          ),
        ]
      ),
    ]),
    _c(
      "div",
      { staticClass: "card-body" },
      [
        _c("BaseListTable", {
          attrs: {
            items: _vm.items,
            "total-items": _vm.totalItems,
            limit: _vm.limit,
            offset: _vm.offset,
            "header-fields": _vm.headerFields,
            type: "posts",
            "has-pagination": "",
          },
          on: { updatePagination: _vm.updatePagination, delete: _vm.remove },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }